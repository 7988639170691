import homeScrenn from '../assets/images/homeScreen.jpg'
import service1 from '../assets/images/service1.webp'
import service2 from '../assets/images/service2.webp'
import service3 from '../assets/images/service3.webp'
import service4 from '../assets/images/service4.webp'
import service5 from '../assets/images/service5.webp'
import service6 from '../assets/images/service6.webp'
import service7 from '../assets/images/service7.webp'
import done from '../assets/images/done-icon.png'
import logo from '../assets/images/logo.png'
const IMAGES = {
    homeScrenn,
    service1,
    service2,
    service3,
    service4,
    service5,
    service6,
    service7,
    done,
    logo
}

export default IMAGES;