import { Facebook, Instagram, LinkedIn } from "@mui/icons-material";
import { Box, Button, TextField, Typography } from "@mui/material"
import { servicesData, solarDesign } from "../../utils/constants";
import { ROUTES } from "../../utils/routes";
import { useNavigate } from "react-router-dom";
import { grey } from "@mui/material/colors";
import { useState } from "react";
import ContactUs from "./contactUs/contactUs";

const Footer = () => {
    const navigate = useNavigate();
    const [email,setEmail]=useState('')
    return (
        <Box sx={{ background: 'black', p: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', flexWrap: 'wrap' }}>

                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography fontWeight={'bold'} color={'GrayText'}>Services</Typography>
                    <Box sx={{ display: 'flex', gap: 10, }}>
                        <Box>
                            <Typography color={'GrayText'} onClick={() => navigate(ROUTES.service, { state: { index: 0 } })} sx={{ cursor: 'pointer', '&:hover': { sm:{color: '#fff'} }, fontSize: '0.8rem' }} variant="body2" my={0.5}>{servicesData[0].title}</Typography>
                            <Typography color={'GrayText'} onClick={() => navigate(ROUTES.service, { state: { index: 1 } })} sx={{ cursor: 'pointer', '&:hover': { sm:{color: '#fff'} }, fontSize: '0.8rem' }} variant="body2" my={0.5}>{servicesData[1].title}</Typography>
                            <Typography color={'GrayText'} onClick={() => navigate(ROUTES.service, { state: { index: 4 } })} sx={{ cursor: 'pointer', '&:hover': { sm:{color: '#fff'} }, fontSize: '0.8rem' }} variant="body2" my={0.5}>{servicesData[4].title}</Typography>
                        </Box>
                        <Box>
                            <Typography color={'GrayText'} onClick={() => navigate(ROUTES.service, { state: { index: 2 } })} sx={{ cursor: 'pointer', '&:hover': { sm:{color: '#fff'} }, fontSize: '0.8rem' }} variant="body2" my={0.5}>{servicesData[2].title}</Typography>
                            <Typography color={'GrayText'} onClick={() => navigate(ROUTES.service, { state: { index: 3 } })} sx={{ cursor: 'pointer', '&:hover': { sm:{color: '#fff'} }, fontSize: '0.8rem' }} variant="body2" my={0.5}>{servicesData[3].title}</Typography>
                        </Box>
                        <Box>
                            <Typography color={'GrayText'} onClick={() => navigate(ROUTES.service, { state: { index: 5 } })} sx={{ cursor: 'pointer', '&:hover': { sm:{color: '#fff'} }, fontSize: '0.8rem' }} variant="body2" my={0.5}>{servicesData[5].title}</Typography>
                            <Typography color={'GrayText'} onClick={() => navigate(ROUTES.service, { state: { index: 7 } })} sx={{ cursor: 'pointer', '&:hover': { sm:{color: '#fff'} }, fontSize: '0.8rem' }} variant="body2" my={0.5}>{solarDesign.title}</Typography>index:
                        </Box>
                    </Box>

                </Box>
                <Box>
                    {/* <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography mx={1} textAlign={'center'} color={'GrayText'} variant="subtitle2">FAQ</Typography>
                    <Typography mx={1} textAlign={'center'} color={'GrayText'} variant="subtitle2">Terms</Typography>
                    <Typography mx={1} textAlign={'center'} color={'GrayText'} variant="subtitle2">About us</Typography>
                </Box> */}
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 1 }}>
                        <Box mx={0.5} color={'GrayText'} sx={{ cursor: 'pointer', '&:hover': { color: '#fff' } }} ><Facebook /> </Box>
                        <Box mx={0.5} color={'GrayText'} sx={{ cursor: 'pointer', '&:hover': { color: '#fff' } }} ><LinkedIn /></Box>
                        <Box mx={0.5} color={'GrayText'} sx={{ cursor: 'pointer', '&:hover': { color: '#fff' } }} ><Instagram /> </Box>
                    </Box>
                    <Box mt={1}>
                        <Typography variant="subtitle2" color={'GrayText'}> Copyright &copy; 2023 gprengg.com - All Rights Reserved.</Typography>
                    </Box>
                </Box>

            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', mt: 3, mb: 2 }}>
                <Typography variant="h6" color={'GrayText'}>CONTACT US </Typography>
                <Box sx={{display:'flex', alignItems:'center', gap:2}}>
                <Typography color={'GrayText'}>Have an interesting project? Leave your email</Typography>
                <ContactUs onFooter={true} />
                </Box>

                {/* <Box component={'form'} onSubmit={(e) => {
                    e.preventDefault()
                    console.log(e,'eded')
                }} sx={{ display: 'flex', alignItems: 'center', mt: 3, width: '100%', justifyContent: 'center' }}>
                    <TextField
                        type="email"
                        fullWidth
                        name="email"
                        size="small"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        sx={{
                            background: grey[100],
                            borderRadius: 3,
                            width: '50%',
                            mr: 3,
                        }}

                    />
                    <Button type="submit" variant="contained">Engage</Button>
                </Box> */}
            </Box>
        </Box>
    )
}

export default Footer;
