import ElectricalAndStrcturalReview from "../screens/service/ESR";
import CommercialService from "../screens/service/commercial";
import EVChargingService from "../screens/service/ev";
import ResidentialService from "../screens/service/residential";
import SolorDesignService from "../screens/service/solarDesign";
import UtilityService from "../screens/service/utility";
import IMAGES from "./images";

export const servicesData = [
    {
        image: IMAGES.service1,
        title: 'Residential PV design',
        desc: 'Residential Solar System design for Landed and Non-landed',
        content:() => <ResidentialService/>
    },
    {
        image: IMAGES.service2,
        title: 'Commercial PV design',
        desc: 'Commercial Solar system design and production estimates.',
        content:() => <CommercialService/>
    },
    {
        image: IMAGES.service3,
        title: 'Utility Solar design',
        desc: 'Utility Scale solar design for developers, EPCs, and Independent power producers and Co-operatives.',
        content:() => <UtilityService/>
    },
    {
        image: IMAGES.service4,
        title: 'EV Charging',
        desc: 'EV Charging systems designed vary from a charging station for single homes to a parking garage of charging stations for many vehicles',
        content:() => <EVChargingService/>
    },
    {
        image: IMAGES.service5,
        title: 'Authority submission',
        desc: 'With a proven history of working with authorities like SPPG, SCDF, CAAS, and RSAF, we provide a streamlined approval process'
    },
    {
        image: IMAGES.service6,
        title: 'Electrical and Structural review',
        desc: 'Engaging in the services of LEW and PE for seamless approval and installation process.',
        content:() => <ElectricalAndStrcturalReview/>
    },
    
]

export const solarDesign = {
    image: IMAGES.service7,
    title: 'Solar Design Services',
    desc: 'Our services involve the comprehensive design and planning of solar installations tailored to meet specific energy needs and site requirements.',
    content:() => <SolorDesignService/>
}