import emailjs from '@emailjs/browser';

const serviceId = process.env.REACT_APP_EMAIL_SERVICE_ID
const templateId = process.env.REACT_APP_EMAIL_TEMPLATE_ID
const publicKey = process.env.REACT_APP_EMAIL_PUBLIC_KEY

export const sendEmail = (data) =>{
	try {
		return emailjs
		.sendForm(serviceId, templateId, data, {
		  publicKey: publicKey,
		})
		.then(
		  () => {
			console.log('SUCCESS!');
		  },
		  (error) => {
			console.log('FAILED...', error);
		  },
		);
	} catch (error) {
	}

}