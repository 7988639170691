import { Box, Button, Typography } from "@mui/material"
import ContactUs from "../contactUs/contactUs";

const HeroSection_1 = ({ image, title, desc, buttonName, onClick, minHeight }) => {
    return (
        <Box
            className="parallex-background"
            sx={{
                backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0)), url(${image})`,
                minHeight: minHeight || '100vh',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundAttachment: 'fixed',
                backgroundPosition: 'center',
                // backgroundImage:  `url(${data.image})`,
                display: 'flex',
                alignItems: 'center',
                // px: {lg:15,md:15,xs:5},
                objectFit: 'cover',
                justifyContent: 'center'

            }}
            flex={1}>
            <Box sx={{ textAlign: 'center', px: '10%' }}>
                {/* <Box sx={{ width: {lg:'50%',md:'50%',xs:'100%'} }}> */}
                <Typography
                    data-aos={"fade-right"}
                    data-aos-offset="50"
                    sx={{
                        fontFamily: 'Lato',

                    }}
                    color="white"
                    variant="h3"
                    fontWeight={'bold'}
                    mb={4}
                >{title}</Typography>
                <Typography
                    data-aos={"fade-right"}
                    data-aos-offset="100"
                    //  data-aos-easing="ease-in-out"
                    sx={{
                        fontFamily: 'Lato',
                        // wordBreak:'break-all',
                        whiteSpace: 'break-spaces'
                    }}
                    variant="h5"
                    color="white"
                    mb={4}
                >{desc}</Typography>
                <ContactUs/>
                {/* <Button
                    data-aos-offset="250"
                    data-aos={"fade-right"}
                    onClick={onClick && onClick}
                    sx={{
                        borderRadius: 5,
                        background: '#ed7171',
                        opacity: 1,
                        '&:hover': {
                            background: '#ed7171',
                            opacity: .8

                        }
                    }}
                    variant="contained">{buttonName}</Button> */}
            </Box>
        </Box>
    )
}

export default HeroSection_1;