import { AppBar, Box, Button, Toolbar, Typography } from "@mui/material"
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom"
import { ROUTES } from "../../utils/routes";
import ContactUs from "./contactUs/contactUs";
import IMAGES from "../../utils/images";

const Navbar = ({ isScroll }) => {
    let color = isScroll ? 'black' : '#fff'
    const location = useLocation();
    const navigation = useNavigate()
    useEffect(() => {
        window.scrollTo({
            top: 0,
        })
    }, [location.pathname])
    return (
        <Box sx={{
            zIndex: 999999,
            background: isScroll ? '#fff' : 'transparent',
            // boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
            width: '100%',
            position: 'fixed',
            top: 0,
        }}>
            <Toolbar disableGutters sx={{ position: 'relative' }}>
                {location.pathname !== '/' ? <Box onClick={() => navigation(-1)} sx={{ position: 'absolute', left: 25, cursor: 'pointer' }}>
                    <svg
                        width="34"
                        height="34"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        color={color}
                    >
                        <path
                            d="M20.3284 11.0001V13.0001L7.50011 13.0001L10.7426 16.2426L9.32842 17.6568L3.67157 12L9.32842 6.34314L10.7426 7.75735L7.49988 11.0001L20.3284 11.0001Z"
                            fill="currentColor"
                        />
                    </svg>
                </Box> : null}
                <Box sx={{ px: { lg: 10, md: 15, xs: 5 }, display: 'flex', width: '100%', ml: 4, pl:1 }}>
                    <Box   onClick={() => navigation(ROUTES.landingPage)} sx={{display:'flex',alignItems:'center',flexGrow:1, cursor:'pointer'}}>
                    <img src={IMAGES.logo} width={30} height={30} style={{ borderRadius: 5 }} />
                    <Typography

                        sx={{ fontFamily: 'Lato',display:{xs:'none', sm:'none',md:'block'} }}
                        variant="h5"
                        flexGrow={1}
                        fontWeight={900}
                        color={color}
                        ml={1}
                    >
                        GPR Engineering
                    </Typography>
                    </Box>
                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>

                            {location.pathname !== '/' ?
                                <Button
                                    onClick={() => navigation(ROUTES.landingPage)}
                                    sx={{ color: color, px: 3 }}>
                                    <Typography
                                        variant="subtitle2"
                                        sx={{
                                            fontFamily: 'Lato',
                                            fontWeight: 'bold',
                                            textDecoration: 'none',
                                            '&:hover': {
                                                color: '#ed7171',
                                                textDecoration: 'underline',
                                                transition: 'text-decoration 0.3s ease'
                                            }

                                        }}>Home</Typography>
                                </Button> : null}
                            <Button sx={{ color: color, px: 3 }}>
                                <Typography
                                    variant="subtitle2"
                                    sx={{
                                        fontFamily: 'Lato',
                                        fontWeight: 'bold',
                                        '&:hover': {
                                            color: '#ed7171',
                                            textDecoration: 'underline',
                                            transition: 'text-decoration 0.3s ease'
                                        }
                                    }}>About Us</Typography>
                            </Button>
                            <ContactUs
                                button={(onClick) => (
                                    <Button onClick={onClick} sx={{ color: color, px: 3 }}>
                                        <Typography
                                            variant="subtitle2"
                                            sx={{
                                                fontFamily: 'Lato',
                                                fontWeight: 'bold',
                                                '&:hover': {
                                                    color: '#ed7171',
                                                    textDecoration: 'underline',
                                                    transition: 'text-decoration 0.3s ease'
                                                }
                                            }}>Contact Us</Typography>
                                    </Button>
                                )}
                            />

                        </Box>
                    </Box>
                </Box>
            </Toolbar >
        </Box >
    )
}
export default Navbar;