import { Box, Typography } from "@mui/material"
import Bullets from "./bullets";

const ElectricalAndStrcturalReview = () => {
    return (
        <Box>
            <Typography mb={3} className="paragraph" >
            Our experienced structural engineers offer a range of services using advanced technology and software to provide innovative solutions that meet regulatory and safety requirements. We specialize in structural design, analysis, seismic engineering, and construction support.
            </Typography>
            <Typography mb={3} className="paragraph" >
            We work closely with architects, builders, and contractors to create integrated designs tailored to specific project requirements. Our expertise in various building materials allows us to design structures of any size or complexity.
            </Typography>
            <Typography mb={3} className="paragraph" >
            We use advanced software to assess the performance of structures under various loads and conditions. Our detailed reports provide recommendations for strengthening or repairing existing structures and designing new structures to meet specific performance requirements.
            </Typography>
            <Typography mb={3} className="paragraph" >
            Our seismic engineering services ensure that structures can withstand earthquakes and other seismic events. We use the latest modeling and analysis techniques to evaluate structural performance and design systems that meet the most stringent seismic codes and standards.
            </Typography>
            <Typography mb={3} className="paragraph" >
            We provide construction documents, shop drawings, and field support to ensure that our designs are implemented correctly. We work closely with contractors and builders to ensure that our designs meet project requirements and specifications.
            </Typography>
            <Typography mb={3} className="paragraph" >
            From a project as simple as a residential rooftop PV system to the most complex airports in the US, our structural expertise can maximize project success.
            </Typography>
            <Typography mb={3} className="paragraph" >
            Contact us to discuss your structural engineering needs (in selected states only). Our experienced team is committed to delivering high-quality designs that meet your specific requirements and budget.
            </Typography>

            <Bullets
            
            data={[
                'Stamped Structural Design & Calculations for solar rooftops.',
                'Stamped Structural Design & Calculations for solar ground mounts.',
                'Stamped Structural Design & Calculations for solar canopies & carports.',
                'Structural Racking and Foundations Design.'
            ]}
            />
        </Box>
    )
}

export default ElectricalAndStrcturalReview;