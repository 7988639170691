import { Box, Typography } from "@mui/material";

const UtilityService = () => {
    return (
        <Box>
            <Typography className="title1" mt={2} >Utility Scale Solar Design Services</Typography>
            <Typography mb={3} className="paragraph" >
                GPR’s Utility Solar team is positioned to deliver Permit/Construction/As-Built Plan Sets, and design support to Developers, EPCs, Solar Asset Owners, and Utilities & Independent Power Producers (IPPs).
            </Typography>
            <Typography mb={3} className="paragraph" >
                We offer services beyond Electrical where partners require Structural, Civil, Rooftop, Shade Structures, Solar PV Systems.
            </Typography>
            <Typography mb={3} className="paragraph" >
                With a Knowledge Bank of Engineers that are in the Singapore and India, GPR is able to bring Global Advantages to your Project Scheduling, Budget, Optimization, and Bankability. If Solar Design isn’t required, allow GPR to review Plan Sets that have been submitted by Third-Party Engineers by our experienced Engineers.
            </Typography>
        </Box>
    )
}

export default UtilityService;