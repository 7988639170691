import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';

export default function PositionedSnackbar({ alert, handleClose }) {
  const [state, setState] = React.useState({
    vertical: 'top',
    horizontal: 'right',
  });
  const { vertical, horizontal } = state;
  console.log(alert,'jgcvjhvcjhcj')
  return (
    <Box sx={{ width: 500 }}>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={2000}
        open={alert.show}
        onClose={handleClose}
        message="I love snacks"
        key={vertical + horizontal}
        sx={{
          '&.MuiSnackbar-root': {
            zIndex: 99999999
          },
          zIndex: 99999999
        }}
      >
        <Alert
          onClose={handleClose}
          severity={alert.type || 'info'}
          variant="filled"
          // sx={{ width: '100%' }}
        >
          {
           alert.msg
          }
        </Alert>
      </Snackbar>
    </Box>
  );
}