import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Box, CircularProgress, IconButton, TextField } from '@mui/material';
import { Send } from '@mui/icons-material';
import { sendEmail } from '../../../service/email.service';
import PositionedSnackbar from '../snackbar/snackBar';
import { useCommonContext } from '../../../utils/contexts/common.context';
import CloseIcon from '@mui/icons-material/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ContactUs = ({ onFooter, button }) => {
    const form = React.useRef();
    const { showSuccessAlert, showErrorAlert } = useCommonContext();
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [isSend, setIsSend] = React.useState(false)
    const onSubmit = (e) => {
        e.preventDefault();
        if (!isSend) {
            try {
                setIsSend(true);
                sendEmail(form.current).then(
                    () => {
                        console.log('SUCCESS!');
                        setIsSend(false);
                        showSuccessAlert('Sent')
                        handleClose()
                    },
                    (error) => {
                        console.log('FAILED...', error);
                        setIsSend(false);
                        showErrorAlert('Something went wrong')
                    },
                );
            } catch (error) {

            }
        }

    }
    return (
        <React.Fragment>
            {button?
            button(handleClickOpen):
                onFooter ?
                    <Button
                        onClick={handleClickOpen}
                        // onClick={onClick && onClick}
                        sx={{
                            borderRadius: 5,
                            // background: '#ed7171',
                            opacity: 1,
                            '&:hover': {
                                // background: '#ed7171',
                                opacity: .8

                            }
                        }}
                        variant="contained">
                        Here
                    </Button>
                    :
                    <Button
                        onClick={handleClickOpen}
                        data-aos-offset="250"
                        data-aos={"fade-right"}
                        // onClick={onClick && onClick}
                        sx={{
                            borderRadius: 5,
                            background: '#ed7171',
                            opacity: 1,
                            '&:hover': {
                                background: '#ed7171',
                                opacity: .8

                            }
                        }}
                        variant="contained">
                        Get In Touch
                    </Button>
            }
            <Dialog
                open={open}
                // TransitionComponent={Transition}
                // keepMounted
                // onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                maxWidth="xs"
                fullWidth
            >
                <DialogTitle sx={{
                    background: '#ed7171',
                    color: '#fff',
                    position: 'relative'
                }} textAlign={'center'}>
                    {"Contact Us"}
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: '#fff',
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <Box ref={form} component={'form'} onSubmit={onSubmit} sx={{ px: 3, display: 'flex', flexDirection: 'column', gap: 2, mt: 3 }}>

                    <TextField
                        size='small'
                        label="Name"
                        name="name"
                        InputLabelProps={{
                            shrink: true
                        }}
                        required
                    />
                    <TextField
                        size='small'
                        label="Email"
                        type='email'
                        name="email"
                        InputLabelProps={{
                            shrink: true
                        }}
                        sx={{
                            border: 'none'
                        }}
                        inputProps={{
                            style: {
                                border: 'none'
                            }
                        }}
                        required
                    />
                    <TextField
                        size='small'
                        label="Message"
                        name="message"
                        InputLabelProps={{
                            shrink: true
                        }}
                        rows={7}
                        multiline
                    />
                    <DialogActions sx={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <Button
                            type='submit'
                            startIcon={isSend ? <CircularProgress sx={{ color: '#fff' }} size={15} /> : <Send />}
                            variant='contained'
                        // onClick={handleClose}
                        >Send</Button>
                    </DialogActions>
                </Box>


            </Dialog>
        </React.Fragment>
    );
}

export default ContactUs;