import { Box, Grid, Typography } from "@mui/material"
import ServiceCards from "./serviceCards";
import IMAGES from "../../utils/images";
import { servicesData, solarDesign } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../utils/routes";
const Services = () => {
    const navigate = useNavigate();


    return (
        <Box>
            <Typography data-aos="flip-left" letterSpacing={1} fontWeight={'bold'} variant="h4" textAlign={'center'}>SERVICES</Typography>
            <Box mt={5}>
                <Grid container spacing={5}>
                    {
                        servicesData?.map((service, index) => (
                            <Grid
                                mt={3}
                                data-aos={index < 3 ? "fade-left" : "fade-right"}
                                sx={{ cursor: 'pointer',display:'flex', justifyContent:'center' }}
                                onClick={() => navigate(ROUTES.service, { state: { index } })}
                                // data-aos-offset="300"
                                // data-aos-easing="ease-in-sine"
                                item lg={4} md={6} sm={6} xs={12}>
                                <Box sx={{ width: 330, height: 300, mt: 4 }}>
                                    <ServiceCards title={service.title} image={service.image} desc={service.desc} />
                                </Box>
                            </Grid>

                        ))
                    }
                </Grid>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 15 }}>
                    <Box sx={{ width: 500, height: 500, cursor: 'pointer' }} onClick={() => navigate(ROUTES.service, { state: { index: 7 } })} >
                        <ServiceCards title={solarDesign.title} image={solarDesign.image} desc={solarDesign.desc} />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default Services;