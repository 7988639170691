import { Box, Typography } from "@mui/material";
import Bullets from "./bullets";

const EVChargingService = () => {
    return (
        <Box>
            <Typography mb={3} className="paragraph" >
                Thanks to our vibrant team of EV enthusiasts, GPR has already designed over 10 EV Charging Stations across Singapore. Quality matters to our engineers more than anything else. It is precisely the reason why we hold an impressive record for first time SP Approvals. Our EVCS Project designs are no different either.
            </Typography>
            <Typography mb={3} className="paragraph" >
                Considering your project requirements, we deliver EV Charging Station Permit Plans, with the assistance of professional engineers with vast experience designing EV infrastructure.
            </Typography>

            <Bullets
                data={[
                    'EV Charging Station Design Layout',
                    ' Feasibility Study',
                    'Electrical and Structural Engineering',
                    'Professional Services',
                    'Project Management'
                ]}
            />

            <Typography mb={3} mt={3} className="paragraph" >
                Our EVCS plan set provides the following details for the installation of the Charging System, with or without battery backup, and for the residential or commercial systems which are Grid-tied or Off-Grid. The plan set is drafted to meet all authority requirements.
            </Typography>


            <Bullets
                data={[
                    'Cover Sheet',
                    'Site Plan',
                    'Electrical Plan and Calculations',
                    'Single Line Diagram',
                    'And all that is necessary for an approval'
                ]}
            />
        </Box>
    )
}

export default EVChargingService;