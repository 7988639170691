import logo from './logo.svg';
import './App.css';
import { Box } from '@mui/material';
import Navbar from './components/navbar/appbar';
import { useEffect, useState } from 'react';
import Footer from './components/navbar/footer';
import 'aos/dist/aos.css';
import AOS from 'aos';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom'
import LandingPage from './screens/landingPage';
import { ROUTES } from './utils/routes';
import "@fontsource/lato"; // Defaults to weight 400
import "@fontsource/poppins"; // Defaults to weight 400
import "@fontsource/poppins/900.css";
import ServicePage from './screens/service/services';
import PositionedSnackbar from './components/navbar/snackbar/snackBar';
import { CommonContext } from './utils/contexts/common.context';

function App() {
  const [isScroll, setIsScroll] = useState(false);
  const location = useLocation();
  useEffect(()=>{
      console.log(location.state, 'ewdyfweyjh')
window.scrollTo({
  top:0,
  behavior:'smooth'
})
    },[location.state?.index])
  useEffect(() => {
    AOS.init({
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      // debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      // throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)


      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      // offset: 120, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 1700, // values from 0 to 3000, with step 50ms
      once: true, // whether animation should happen only once - while scrolling down
    });
    window.addEventListener('scroll', () => {
      const height = window.scrollY
      if (height > 100) {
        setIsScroll(true)
      } else setIsScroll(false)
    })
  }, [])
  const [alert, setAlert] = useState({
    show: false,
    type: '',
    msg: ''
  })
  const showSuccessAlert = (msg) => {
    console.log('67f8o8fug')

    setAlert({
      show: true,
      type: "success",
      msg: msg
    })
  }
  const showErrorAlert=(msg)=>{
    setAlert({
      show: true,
      type: "error",
      msg: msg
    })
  }
  const handleCloseAlert = () =>{
    setAlert({
      show: false,
      type: '',
      msg: ''
    }) 
  }
  return (
    <CommonContext.Provider value={{
      showSuccessAlert,
      showErrorAlert
    }}>
      <Box
        // className="App"
        sx={{ display: 'flex', flexDirection: 'column', overflow:'hidden' }}
      >
        <Box flex={1}>
            <Navbar isScroll={isScroll} />
            <Routes>
              <Route path={ROUTES.landingPage} element={<LandingPage />} />
              <Route path={ROUTES.service} element={<ServicePage />} />
            </Routes>
            <Footer />
        </Box>
        <PositionedSnackbar handleClose={handleCloseAlert} alert={alert} />
      </Box>
    </CommonContext.Provider>
  );
}

export default App;
