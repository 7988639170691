import { Box, Card, CardContent, CardHeader, CardMedia, Grid, Typography } from "@mui/material"

const ServiceCards = ({ image, title, desc, height }) => {
    return (
        <Box container sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography mb={2} variant="h6" textAlign={'center'}>{title}</Typography>
            <CardMedia
                component="img"
                // width={'194'}
                // height={'194'}
                image={image}
                alt="Services"
                sx={{
                    transition: 'transform 0.5s ease',
                    // aspectRatio: ' 1/1',
                    // objectFit: 'contain',
                    '&:hover': {
                        transform: 'scale(1.05,1.05)',
                        boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
                    },
                    borderRadius:4
                }}
            />
            <Typography mt={2} variant="body2" maxWidth={'100%'} textAlign={'start'}>
                {desc}
            </Typography>
        </Box>
    )
}

export default ServiceCards;