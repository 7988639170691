import { Box, Typography } from "@mui/material"
import Bullets from "./bullets";

const ResidentialService = () => {
    return (
        <Box>

            <Typography className="paragraph" mt={4} >
                At GPR, we excel in designing efficient, code-compliant solar systems, overcoming obstacles, and exceeding expectations.
            </Typography>

            <Typography className="title1" mt={2} >‍Our Approach</Typography>

            <Typography className="paragraph" mt={2} >
                <span className="title2">Deep Technical Proficiency:</span> &nbsp;&nbsp;
                With a comprehensive understanding of site constraints, and system specifications, our experienced team specializes in optimizing system sizing, addressing shading concerns, and ensuring seamless integration with existing electrical systems.
            </Typography>

            <Typography className="paragraph" mt={2} >
                <span className="title2">Cutting-Edge Technology Integration:</span> &nbsp;&nbsp;
                At GPR, we stay at the forefront of solar advancements, empowering our clients with the latest solutions in solar panel technology, mounting solutions, and energy storage. By continuously researching and implementing cutting-edge technologies, we provide tailored designs that not only meet but exceed evolving client needs.
            </Typography>

            <Typography className="paragraph" mt={2} >
                <span className="title2">Aesthetically Driven: </span> &nbsp;&nbsp;
                We recognize the significance of aesthetics in residential solar installations. Collaborating closely with installers and contractors, we place great emphasis on the seamless integration of solar systems with architectural design. Our designs not only meet energy goals but also enhance the overall aesthetics of the residence.
            </Typography>

            <Typography className="title1" mt={2} >Features</Typography>

            <Typography className="paragraph" mt={2} mb={2} >
                Our residential solar design services encompass a comprehensive range of features that ensure the success of your solar installation project. With meticulous attention to detail and expertise in solar engineering, we provide the following key features, along with all the necessary components for obtaining an approved permit:
            </Typography>

            <Bullets data={
                [
                    'Schematic line drawings',
                    'Two-dimensional layout drawings',
                    'Site installation drawings',
                    'Roof or ground mounting schematics',
                ]
            } />

            <Typography className="paragraph" mt={2} mb={2} >
                These features form the foundation of our comprehensive residential solar design services, equipping you with the precise plans and documentation required for a seamless and efficient installation process.
            </Typography>

            <Typography className="title1" mt={2} >Residential Solar Design Offerings</Typography>

            <Typography className="paragraph" mt={2} mb={2} >
                ‍Our typical residential solar permit plan set goes above and beyond to provide you with a comprehensive package of essential elements that form the blueprint for your successful residential solar installation.
            </Typography>

            <Typography className="paragraph" mt={2} mb={2} >
                Take advantage of the following components meticulously crafted by our expert team:
            </Typography>


            <Bullets data={
                [
                    'A comprehensive cover sheet that sets the stage for your solar project.',
                    'A detailed site plan that captures every aspect of your property, ensuring optimal panel placement.',
                    'A roof layout specifically designed to achieve precise solar panel positioning, maximizing energy capture.',
                    'An electrical plan meticulously crafted to seamlessly integrate your solar system with your existing electrical infrastructure.',
                    'A Single Line Diagram (SLD) that visually illustrates the electrical connections, providing a clear overview of your solar setup.',
                    'A stringing configuration plan (If required) optimized for your specific project, ensuring the most efficient arrangement of your solar panels.'
                ]
            } />

            <Typography className="paragraph" mt={2} mb={2} >
                These elements come together in our comprehensive solar permit plan set, will provide a solid foundation for a successful residential solar installation.
            </Typography>
        </Box>


    )
}

export default ResidentialService;