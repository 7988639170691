import { Box, Typography } from "@mui/material"
import IMAGES from "../../utils/images";
import './styles.css';
const Bullets = ({ data = [] }) => {
    return (
        <>
            {
                data.map(point => (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <img src={IMAGES.done} width={15} height={15}/>
                        <Typography className="paragraph" ml={2}>{point}</Typography>
                    </Box>
                ))
            }
        </>
    )
}

export default Bullets;