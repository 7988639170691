import { Box, Typography } from "@mui/material"
import Bullets from "./bullets"

const SolorDesignService = () => {
    return (
        <Box>
            <Typography mb={3} className="paragraph" >
                When it comes to launching successful solar PV projects, striking the right balance between timing and quality is essential. That's why we at GPR provide you with the resources, expertise, and guidance you need to achieve both, every step of the way.
            </Typography>
            <Typography mb={3} className="paragraph" >
                At GPR, we're more than just a company – we're a team of passionate solar enthusiasts, who are committed to helping our clients succeed.
            </Typography>
            <Typography mb={3} className="paragraph" >
                Our in-house solar design engineering team consists of a group of savvy solar aficionados, who possess extensive knowledge and experience in all the necessary local codes and regulations for successful solar PV installations. We'll work with you through every stage of your solar project, to design custom solutions that are optimized for maximum energy production, cost effectiveness, visual appeal, and ease of installation.
            </Typography>
            <Typography mb={3} className="paragraph" >
                Our solar permit plan sets are carefully crafted to meet all local Singapore requirements, and includes the following:
            </Typography>

            <Bullets
                data={[
                    'Cover Sheet',
                    'Site Plan',
                ]}
            />

            <Typography mb={3} className="paragraph" >
            We also know that time is money in the solar industry, and that's why we have a quick turnaround time for our solar design services. Our goal is to help you start generating revenue from your solar PV projects as soon as possible, so you can focus on what you do best –installing high-quality solar PV systems for your clients.
            </Typography>
            <Typography mb={3} className="paragraph" >

            At GPR, we believe that each project is unique. We hence take the time to offer you personalized attention to every detail of your solar project. Our experts work closely with you throughout the entire solar design process to ensure that your specific needs are met, and we provide ongoing support throughout the installation process to ensure that your projects are completed to your satisfaction.
            </Typography>
            <Typography mb={3} className="paragraph" >
            Our mission is to make solar energy accessible to everyone. Whether you're an industry veteran or new to solar energy, our team is eager to help you bring your project to life.
            </Typography>
            <Typography mb={3} className="title1" >
            Why Choose Us?
            </Typography>
            <Bullets
            data={[
                'Short turnaround time.',
                'Authority corrections (if any) handled at no additional cost.',
                'Extensive experience with various structural attachments, modules, batteries, and inverters.',
            ]}
            />

        </Box>
    )
}

export default SolorDesignService;