import { Box, Typography } from "@mui/material";

const CommercialService = () => {
    return (
        <Box>
            <Typography mb={3} className="paragraph" >
                GPR have done more than 70 MW size of projects across Canada and singapore in the commercial and industrial sector. With NABCEP certified engineers at our disposal, we focus on technical precision and constructability. We also have in-house capabilities to design solar carports.
            </Typography>
            <Typography mb={3} className="paragraph" >
                Our team is also adept at providing PVSyst reports to determine annual energy generation of your commercial systems. As a part of energy analysis/modelling we review utility bills, investigate facility energy consumption, determine size of the PV plant required to offset power requirements, develop PVSyst model with solar resource assessment, build shading scenes, optimize PV plant production and provide P50/P95/P99 energy estimates.
            </Typography>
            <Typography mb={3} className="paragraph" >

            When it comes to commercial solar energy solutions, you need to be able to rely fully on the continuous operation of your system. If you do not use a solar battery that stores excess solar energy, it is unlikely that you will be able to completely disconnect from the grid when installing a commercial solar energy system, and the future energy costs of your utility company will greatly affect the value of the commercial solar system and your business.
            </Typography>
        </Box>
    )
}

export default CommercialService;