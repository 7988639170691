import { Box, Typography } from "@mui/material"
import Services from "../components/navbar/services"
import IMAGES from "../utils/images";
import HeroSection_1 from "../components/navbar/heroSection/heroSection";
import { onClickGetInTouch } from "../utils/utils";

const LandingPage = ({ Navbar }) => {
    return (
        <>
              <HeroSection_1
                image={IMAGES.homeScrenn}
                title={`LET'S MAKE ENERGY SIMPLE`}
                // desc={'future of the earth is renewable energy'}
                buttonName={'Get in Touch'}
                onClick={onClickGetInTouch}
            />
            <Box sx={{ px: { lg: 20, sm: 2, xs: 2 }, mt: 3 }}>
                <Services />
            </Box>
       
        </>
    )
}

export default LandingPage;