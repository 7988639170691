import { Box, Button, Typography } from "@mui/material"
import { useLocation } from "react-router-dom";
import { servicesData, solarDesign } from "../../utils/constants";
import IMAGES from "../../utils/images";
import HeroSection_1 from "../../components/navbar/heroSection/heroSection";
import  './styles.css'
import { useEffect } from "react";
const ServicePage = () => {
    const location = useLocation();
    const data = location.state?.index === 7 ? solarDesign : servicesData[location.state?.index] 
    console.log(location, 'ewdyfweyjh')

    return (
        <>
            <HeroSection_1
                image={data?.image}
                title={data?.title}
                desc={data?.desc}
                buttonName={'Get in Touch'}
                onClick={() => { }}
                minHeight={'80vh'}
            />
            <Box sx={{px:{lg:15,md:15,xs:5}}} mb={5} mt={5}>
                {data?.content && data?.content()}
            </Box>
        </>

    )
}

export default ServicePage;